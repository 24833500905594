<template>
  <div class="main" style="margin: 10px;">
    <div class="container" style="height: 55px; padding-bottom: 50px;">
      <div class="image2">
        <el-image :src="'./images/header-log.png'" />
      </div>
    </div>
    <div v-if="store.state.redeemResult.code=='ok'">
      <h2>{{getI18n('兑换成功', 'redeem success')}}</h2>
      <div v-if="store.state.redeemResult.data.qrcode!=''">
        <img :src="store.state.redeemResult.data.qrcode" class="image-style" />
      </div>
    </div>
    <div v-else>
      <h2>{{getI18n('兑换失败', 'redeem fail')}}:{{ store.state.redeemResult.message }}</h2>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import store from '../store'
import { getI18n } from '../utils'

export default defineComponent({
  name: 'RedeemGiftResultView'
})
</script>

<script lang="ts" setup>
onMounted(async () => {
  console.log('RedeemGiftResultView', store)
})
</script>

<style scoped>
</style>
