import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AndroidInstallView from '../views/AndroidInstallView.vue'
import AppleInstallView from '../views/AppleInstallView.vue'
import FAQZhView from '../views/FAQZhView.vue'
import FAQEnView from '../views/FAQEnView.vue'
import SupportDeviceView from '../views/SupportDeviceView.vue'

import RedeemGiftView from '../views/RedeemGiftView.vue'
import RedeemGiftResultView from '../views/RedeemGiftResultView.vue'
import CarrierCardView from '../views/CarrierCardView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/helper/carrier',
    name: 'CarrierCardView',
    component: CarrierCardView
  },
  {
    path: '/redeem-gift-result',
    name: 'RedeemGiftResultView',
    component: RedeemGiftResultView
  },
  {
    path: '/redeem-gift',
    name: 'RedeemGiftView',
    component: RedeemGiftView
  },
  {
    path: '/helper/install/android',
    name: 'AndroidInstallView',
    component: AndroidInstallView
  },
  {
    path: '/helper/install/apple',
    name: 'AppleInstallView',
    component: AppleInstallView
  },
  {
    path: '/helper/faq/zh',
    name: 'FAQZhView',
    component: FAQZhView
  },
  {
    path: '/helper/faq/en',
    name: 'FAQEnView',
    component: FAQEnView
  },
  {
    path: '/helper/support-device',
    name: 'SupportDeviceView',
    component: SupportDeviceView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
