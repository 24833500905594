<template>
  <router-view/>
</template>

<style lang="scss">
:root {
  --el-font-size-base: 16px !important; /* 设置新的基础字体大小 */
}
.el-tag {
  --el-tag-font-size: 18px !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // font-size: 48px;

  display: flex;
  justify-content: center;
}
.main {
  /* background-color: rgb(243, 243, 243); */
  // background-color: white;
  // background-color: lightblue;
  // background-color: lightgray;
  background-color: #fafafa; /* HEX 颜色代码 */
  margin: 0px !important;
}
.card {
  background-color: white;
}
.radius {
  /* width: 100%; */
  border: 1px solid var(--el-border-color);
  border-radius: 0;
  margin-top: 20px;
}
.data-radius {
  /* width: 80%; */
  border: 1px solid var(--el-border-color);
  border-radius: 0;
  margin-top: 10px;
  background-color:white;
}
.divider {
  margin: 2px;
}
.card-row {
  text-align: left;
  align-items: center;
  padding: 5px; /* 可选的填充 */
  margin: 0px !important;
}
.card-row-query {
  text-align: right;
  align-items: center;
  padding: 5px; /* 可选的填充 */
  margin: 0px !important;
}
.col-title {
  text-align: right;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.col-button {
  text-align: right;
  padding-right: 20px !important;
}
.font-red {
  color: red;
  font-weight: bold;
}
.font-red2 {
  color: red;
  font-weight: bold;
  font-size: 10px;
}
.value-font {
  font-weight: bold;
}
li {
  text-align: left; /* 确保文字左对齐 */
  padding-left: 0;  /* 如果有内边距，可以将其设置为0 */
  margin-left: 0;   /* 如果有外边距，可以将其设置为0 */
}
</style>
