<template>
  <div class="main" style="margin: 10px;">
    <div class="container" style="height: 55px; padding-bottom: 50px;">
      <div class="image2">
        <el-image :src="'./images/header-log.png'" />
      </div>
    </div>
    <el-form :model="form" label-width="auto" :action="'http://127.0.0.1:5000/form-submit2'" style="max-width: 600px">
      <el-form-item :label="getI18n('兑换码', 'Redeem Code')">
        <el-input v-model="form.redeemCode" />
      </el-form-item>
      <el-form-item :label="getI18n('邮箱', 'email')">
        <el-input v-model="form.email" />
      </el-form-item>
      <el-form-item :label="getI18n('兑换类型', 'Redeem Type')">
      <el-radio-group v-model="form.redeemType" name="redeemType">
        <el-radio :value="'esim'" :label="'esim'">ESIM</el-radio>
        <el-radio :value="'topUp'" :label="'topUp'">{{getI18n('充值', 'Recharge')}}</el-radio>
      </el-radio-group>
      </el-form-item>
        <el-form-item label="ICCID" v-if="form.redeemType==='topUp'">
          <el-input v-model="form.simNo" />
        </el-form-item>
        <el-form-item :label="getI18n('套餐', 'Package')">
        <el-select class="custom-select-height" v-model="form.package" name="region" placeholder="please select your package">
          <el-option label="China 1GB 365 Days" value="china1GB365Days" />
          <el-option label="China 5GB 365 Days" value="china5GB365Days" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="col-button">
          <el-button type="primary" @click="onSubmit">{{getI18n('兑换', 'redeem')}}</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import store from '../store'
import { apiPostData, getI18n } from '../utils'
import router from '@/router'

export default defineComponent({
  name: 'RedeemGiftView'
})
</script>

<script lang="ts" setup>
// do not use same name with ref
const form = reactive({
  redeemCode: 'a00001',
  email: '1@qq.com',
  package: 'china1GB365Days',
  redeemType: 'esim',
  simNo: ''
})

const onSubmit = () => {
  console.log('submit!')
  const data = {
    redeemCode: form.redeemCode,
    email: form.email,
    package: form.package,
    redeemType: form.redeemType,
    simNo: form.simNo
  }
  // 这样提交的只有默认的表单数据，没有文件的数据
  apiPostData('/rdm/v1/redeem_code/redeem-gift', data)
    .then(data => {
      console.log('onSubmit data=', data)
      store.state.redeemResult = data
      // window.location.href = '/redeem-gift-result'
      router.push('/redeem-gift-result')
    })
}
</script>

<style scoped>
.button-font {
  color: white;
  font-weight: bold;
}
.container {
  position: relative;
}
.el-row-margin {
  margin: 10px;
}
.el-button {
  display: flex;
  justify-content: flex-end;
  height: 40px;
}
.warning {
  color: red;
  font-weight: bold;
  font-size: 24px;
}
/* 设置自定义类的样式 */
.custom-select-height {
    cursor: pointer;
    width: 100%;
  }
@media screen and (min-width: 600px) {
  .main {
    max-width: 600px; /* 或根据需要设置为其他宽度 */
    width: 600px;
    margin: 0 auto; /* 使内容居中 */
    padding: 0 10px; /* 给两边留一些空白 */
  }

  /* 设置自定义类的样式 */
  .custom-select-height {
    height: 40px;
  }
  .custom-select-height ::v-deep .el-input__inner {
    font-size: 24px;
    height: 40px;
  }
  .button-font {
    font-size: 24px;
  }
  /* 设置自定义类的样式 */
  .custom-select-height {
    cursor: pointer;
    width: 100%;
  }
  .col-button {
    justify-content: flex-end;
    display: flex;
    width: 100%;
    padding-right: 20px !important;
  }
}
</style>
