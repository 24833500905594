<template>
  <div class="data-radius" :style="{borderRadius: '4px'}">
    <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="8" class="col-title">
        <el-text class="mx-1">{{getI18n('地区', 'Region')}}:</el-text>
      </el-col>
      <el-col :span="16">
        <el-text class="mx-1" type="primary">{{props.regionName}}</el-text>
      </el-col>
    </el-row>
    <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="8" class="col-title">
        <el-text class="mx-1">{{getI18n('分类', 'Category')}}:</el-text>
      </el-col>
      <el-col :span="16">
        <el-text class="mx-1" type="primary">{{props.continent}}</el-text>
      </el-col>
    </el-row>
    <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="8" class="col-title">
        <el-text class="mx-1">{{getI18n('运营商', 'Carrier')}}:</el-text>
      </el-col>
      <el-col :span="16">
        <el-text class="mx-1" type="primary">{{props.carrier}}</el-text>
      </el-col>
    </el-row>
    <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="8" class="col-title">
        <el-text class="mx-1">{{getI18n('APN', 'APN')}}:</el-text>
      </el-col>
      <el-col :span="16">
        <el-text class="mx-1" type="primary">{{props.apn}}</el-text>
      </el-col>
    </el-row>
    <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="8" class="col-title">
        <el-text class="mx-1">{{getI18n('支持Tiktok', 'Support Tiktok')}}:</el-text>
      </el-col>
      <el-col :span="16">
        <el-text class="mx-1" type="primary">{{props.supportTiktok}}</el-text>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, defineProps } from 'vue'
import { getI18n } from '../utils'

export default defineComponent({
  name: 'CarrierCard'
})
</script>

<script lang="ts" setup>
const props = defineProps({
  regionName: {
    type: String,
    require: true
  },
  continent: {
    type: String,
    require: true
  },
  carrier: {
    type: String,
    require: true
  },
  apn: {
    type: String,
    require: true
  },
  supportTiktok: {
    type: String,
    require: true
  }
})
</script>
