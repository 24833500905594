<template>
  <template v-if="getTotalUsage()>0">
    <div class="data-radius" :style="{borderRadius: '4px'}">
      <!-- 每日流量明细 -->
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="12">
          <el-tag :type="'success'" effect="light" >{{getI18n('总用量', 'Total Usage')}}:</el-tag>&nbsp;
          <el-tag effect="light" >{{ getTotalUsage() }}(M)</el-tag>
        </el-col>
        <el-col :span="12" class="col-right">
          <el-tag :type="'success'" effect="light" >{{getI18n('套餐流量详情', 'Package Data Detail')}}</el-tag>&nbsp;
          <el-tag effect="light" ><el-icon><Bottom /></el-icon></el-tag>
        </el-col>
      </el-row>
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="8">
          <el-text class="mx-1">{{getI18n('发生日期', 'Date')}}(UTC):</el-text>
        </el-col>
        <el-col :span="86">
          <el-text class="mx-1">{{getI18n('国家', 'Country')}}:</el-text>
        </el-col>
        <el-col :span="8">
          <el-text class="mx-1">{{getI18n('用量', 'Usage')}}(M):</el-text>
        </el-col>
      </el-row>
      <template v-if="!isEmptyArray(props.historyQuota)">
        <template  v-for="detail in props.historyQuota" :key="detail.time">
          <el-row class="card-row" :gutter="20" align="middle" v-if="detail.qtaconsumption!=0">
            <el-col :span="8">
              <el-text class="mx-1" type="primary">{{detail.time}}</el-text>
            </el-col>
            <el-col :span="8">
              <el-text class="mx-1" type="primary">{{getCountryNameByMcc(detail.mcc)}}</el-text>
            </el-col>
            <el-col :span="8">
              <el-text class="mx-1" type="primary">{{detail.qtaconsumption}}</el-text>
            </el-col>
          </el-row>
        </template>
      </template>
    </div>
  </template>
  <template v-else>
    <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="24" class="line-center">
        <el-text class="mx-1 line-center-value">{{getI18n('无数据', 'No Data')}}</el-text>
      </el-col>
    </el-row>
  </template>
</template>

<script lang="ts">
import { defineComponent, defineProps } from 'vue'
import { isEmptyArray, getI18n, getCountryNameByMcc } from '../utils'
import {
  Bottom
} from '@element-plus/icons-vue'
export default defineComponent({
  name: 'PackageDataDetailCard'
})
</script>

<script lang="ts" setup>
const props = defineProps({
  historyQuota: {
    type: Object,
    require: true
  }
})
function getTotalUsage (packageType: string) {
  let totalUsage = 0.0
  for (let i = 0; i < props.historyQuota.length; i++) {
    totalUsage += Number(props.historyQuota[i].qtaconsumption)
  }
  return totalUsage.toFixed(2)
}
</script>

<style scoped>
.line-center {
  text-align: center;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.line-center-value {
  font-size: 18px;
  color: grey;
}
.col-right {
  text-align: right;
  padding-right: 20px !important;
}
</style>
