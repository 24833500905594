<template>
  <div class="data-radius main" :style="{borderRadius: '4px'}" style="width: 100%">
    <span class="title-font">{{getI18n('运行商里列表','Carrier List')}}</span>
    <el-row align="middle" class="el-row-margin">
      <el-col :span="8" style="display: flex; justify-content: flex-end;">
        <el-tag type="success">{{getI18n('过滤器', 'Filter')}}</el-tag>
      </el-col>
      <el-col :span="16">
        <el-input v-model="refFilter" style="width: 100%; margin-left: 10px;" placeholder="Please input" @input="fitlerCarrier" />
      </el-col>
    </el-row>
    <div class="mb-4 button-container">
      <template v-for="item in refFilterItems" :key="item">
        <el-button @click="filter(item)">{{item}}</el-button>
      </template>
    </div>
    <template v-for="carrier in refCarrier" :key="carrier.region_name">
      <CarrierCard :regionName="carrier.region_name" :continent="carrier.continent" :carrier="carrier.carrier" :apn="carrier.apn" :supportTiktok="carrier.support_tiktok"></CarrierCard>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { getI18n, isChinese, apiFetchData } from '../utils'
import CarrierCard from '../components/CarrierCard.vue'

export default defineComponent({
  name: 'CarrierCardView'
})
</script>

<script lang="ts" setup>
const refCarrier = ref([])
const refFilter = ref('')
let carrierList = []
const refFilterItems = ref([])

function fitlerCarrier () {
  console.info('fitlerCarrier', refFilter)
  const lowerStr = refFilter.value.toLowerCase() // 转换为小写
  refCarrier.value = []
  for (const item of carrierList) {
    console.info(item.region, lowerStr)
    if (item.region_name.toLowerCase().includes(lowerStr) || item.continent.toLowerCase().includes(lowerStr)) {
      refCarrier.value.push(item)
    }
  }
}

function filter (item) {
  if (item === 'All' || item === '全部') {
    refFilter.value = ''
  } else {
    refFilter.value = item
  }
  fitlerCarrier()
}
onMounted(async () => {
  let url = '/h5/v1/carrier/query-cc?language='
  if (isChinese()) {
    url += 'zh_CN'
    refFilterItems.value = ['全部', '港澳台', '亚洲', '欧洲', '美洲', '非洲', '大洋洲']
  } else {
    url += 'en_US'
    refFilterItems.value = ['All', 'Hong Kong, Macao, Taiwan', 'Asia', 'Europe', 'America', 'Africa', 'Oceania']
  }
  apiFetchData(url)
    .then(data => {
      if (data !== null && data !== undefined) {
        carrierList = data.data
        fitlerCarrier()
      }
    })
})
</script>

<style scoped>
.el-row-margin {
  margin: 10px;
}
.line-center {
  text-align: center;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.line-center-value {
  font-size: 32px;
}
.title-font {
  font-weight: bold;
  font-size: 32px;
  margin: 10px;
}
/* 如果使用 Element Plus */
.button-container .el-button {
  margin-bottom: 8px; /* 调整这个值来控制间距 */
}
@media screen and (min-width: 600px) {
  .main {
    max-width: 600px; /* 或根据需要设置为其他宽度 */
    width: 600px;
    margin: 0 auto; /* 使内容居中 */
    padding: 0 10px; /* 给两边留一些空白 */
  }

  /* 设置自定义类的样式 */
  .custom-select-height {
    height: 40px;
  }
  .custom-select-height ::v-deep .el-input__inner {
    font-size: 24px;
    height: 40px;
  }
  .button-font {
    font-size: 24px;
  }
}
</style>
