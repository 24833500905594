<template>
  <div class="data-radius" :style="{borderRadius: '4px'}">
    <el-row class="card-row" :gutter="15" align="middle">
        <el-col :span="15">
          <el-text class="mx-1">{{getI18n('查询地址', 'Query Website')}}:</el-text>
          <el-text class="mx-1"><span class="font-red">https://h.newtechlife.net/</span></el-text>
        </el-col>
        <el-col :span="9" class="col-button">
        <el-button plain @click="querySimData('abc')">
          <span type="success">{{getI18n('查询SIM卡','Query SIM')}}</span>
          <el-icon><ArrowDown v-if="!refIsExpand"/><ArrowUp v-if="refIsExpand" /></el-icon>
        </el-button>
      </el-col>
    </el-row>
    <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="24">
        <el-text class="mx-1"><span class="font-red">{{getI18n("SIM卡的有效期是2年，每次使用都会顺延2年", "Extend SIM card validity of 2 years when use any data or order new package once.")}}</span></el-text>
      </el-col>
    </el-row>
    <template v-if="store.state.isQueriedSim">
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="9" class="col-title">
          <el-text class="mx-1">{{getI18n('SIM卡过期时间', 'SIM Expire Time')}}:</el-text>
        </el-col>
        <el-col :span="15">
          <el-text class="mx-1" type="primary">{{refSimExpireTime}}</el-text>
        </el-col>
      </el-row>
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="9" class="col-title">
          <el-text class="mx-1">{{getI18n('实名结果', 'Real Name Result')}}:</el-text>
        </el-col>
        <el-col :span="15">
          <el-text class="mx-1" type="primary">{{refRealName1}}</el-text>
        </el-col>
      </el-row>
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="9" class="col-title">
        </el-col>
        <el-col :span="15">
          <el-text class="mx-1" type="primary">{{refRealName2}}</el-text>
        </el-col>
      </el-row>
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="9" class="col-title">
          <el-text class="mx-1">{{getI18n('ESIM安装设备', 'Installed Device')}}:</el-text>
        </el-col>
        <el-col :span="15">
          <el-text class="mx-1" type="primary">{{refInstalledDevice}}</el-text>
        </el-col>
      </el-row>
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="9" class="col-title">
          <el-text class="mx-1">{{getI18n('ESIM安装状态', 'Installed Status')}}:</el-text>
        </el-col>
        <el-col :span="15">
          <el-text class="mx-1" type="primary">{{ refInstalledStatus }}</el-text>
        </el-col>
      </el-row>
      <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="9" class="col-title">
          <el-text class="mx-1">{{getI18n('当前位置', ' Current Location')}}:</el-text>
        </el-col>
        <el-col :span="15">
          <el-text class="mx-1" type="primary">{{refLocation}}</el-text>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, defineProps, ref } from 'vue'
import { ArrowDown, ArrowUp } from '@element-plus/icons-vue'
import { getI18n, convertTimestamp, getCountryNameByMcc, apiPostData } from '../utils'
import store from '../store'

export default defineComponent({
  name: 'IccidCard'
})
</script>

<script lang="ts" setup>
const props = defineProps({
  simNo: {
    type: String,
    require: true
  }
})

function querySimData () {
  store.state.isQueriedSim = !store.state.isQueriedSim
  if (!store.state.isQueriedSim) {
    return
  }
  refSimExpireTime.value = ''
  refRealName1.value = ''
  refRealName2.value = ''

  querySimInfo()
  getLocation()
  queryEsimInfo()
}
function getRealNameResult (idx) {
  if (simInfoResp.code !== '0') {
    return ''
  }
  const ruleID = simInfoResp.himsis[0].realRuleList[idx].ruleID
  const authStatus = simInfoResp.himsis[0].realRuleList[idx].authStatus
  let strAuthStatus = ''
  if (authStatus === null) {
    strAuthStatus = getI18n('未实名', 'Not registered')
  } else if (authStatus === '1') {
    strAuthStatus = getI18n('代认证', 'To be certified')
  } else if (authStatus === '2') {
    strAuthStatus = getI18n('认证中', 'Verifying')
  } else if (authStatus === '3') {
    strAuthStatus = getI18n('已认证', 'Certified')
  } else if (authStatus === '4') {
    strAuthStatus = getI18n('认证失败', 'Authentication failed')
  } else if (authStatus === '5') {
    strAuthStatus = getI18n('实名过期', 'The certificate has expired')
  }

  return getCountryNameByMcc(ruleID) + ':' + strAuthStatus
}

const refIsExpand = ref(false)
const refSimExpireTime = ref('')
const refRealName1 = ref('')
const refRealName2 = ref('')
let simInfoResp = {}
function querySimInfo () {
  console.info('querySimInfo', props.simNo)
  const data = { iccid: props.simNo }
  apiPostData('/cmm/v1/query-sim-info', data)
    .then(data => {
      if (data !== null && data !== undefined) {
        simInfoResp = data
        refSimExpireTime.value = convertTimestamp(simInfoResp.himsis[0].expireTime)
        refRealName1.value = getRealNameResult(0)
        refRealName2.value = getRealNameResult(1)
      }
    })
}

const refLocation = ref('')
function getLocation () {
  refLocation.value = ''
  const data = { simNo: props.simNo }
  apiPostData('/h5/v1/sim/get-location', data)
    .then(data => {
      console.log('getLocation', data)
      if (data !== null && data !== undefined && data.code === 'ok') {
        refLocation.value = getCountryNameByMcc(data.data.mcc)
      }
    })
}

const refInstalledDevice = ref('')
const refInstalledStatus = ref('')

function queryEsimInfo () {
  refLocation.value = ''
  const data = { simNo: props.simNo }
  apiPostData('/h5/v1/esim/get-info', data)
    .then(data => {
      console.log('queryEsimInfo', data)
      if (data !== null && data !== undefined) {
        console.log('queryEsimInfo', data)
        if (data.code === 'ok') {
          data = data.data
          refInstalledDevice.value = data.installDevice
          refInstalledStatus.value = data.status
          if (data.status === 'Released') {
            refInstalledStatus.value = getI18n('未安装', 'Uninstall')
          } else if (data.status === 'Installed') {
            refInstalledStatus.value = getI18n('已安装', 'Installed')
          } else if (data.status === 'Enable') {
            refInstalledStatus.value = getI18n('启用', 'Enable')
          } else if (data.status === 'Disable') {
            refInstalledStatus.value = getI18n('停用', 'Disable')
          } else if (data.status === 'Unkown') {
            refInstalledStatus.value = getI18n('未知', 'Unkown')
          }
        }
        if (data.code === 'is_pyhsical_card') {
          refInstalledDevice.value = getI18n('物理卡', 'Physical Card')
        }
      }
    })
}

</script>

<style scoped>
.line-center {
  text-align: center;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.line-center-value {
  font-size: 32px;
}
</style>
