<template>
  <div class="div-margin-top">
    <el-image :src="'./images/banner01.png'"/>
    <ul align>
      <li> {{getI18n("到达目的地套餐自动激活", 'Automatic activation of package upon arrival at destination')}}</li>
      <li> {{getI18n("请切换到我们的卡，打开漫游，如果不能联网，关闭打开分型模式试试，或重启手机", 'Please switch to our SIM card, turn on roaming, and if you cannot connect to the network, try turning airplane mode on/off, restart your phone')}}</li>
      <li> {{getI18n("APN 是 cmlink", 'APN is cmlink')}}</li>
      <li> {{getI18n("随时可以充值", "Recharge data at any time.")}}</li>
      <li> {{getI18n("支持100多个国家，一卡走遍世界", "Supports over 100 countries; one card for the world.")}}</li>
      <li> {{getI18n("SIM卡的有效期是2年，每次使用都会顺延2年", "Extend SIM card validity of 2 years when use any data or order new package once.")}}</li>
      <li> <el-link type="primary" :href="'/helper/install/android'">{{getI18n("安卓安装ESIM", 'Installing ESIM on Android')}}</el-link></li>
      <li> <el-link type="primary" :href="'/helper/install/apple'">{{getI18n("苹果安装ESIM", 'Installing ESIM on Apple')}}</el-link></li>
      <li> <el-link type="primary" :href="'/helper/support-device'">{{getI18n("适用机型", 'Compatible Devices')}}</el-link></li>
      <li> <el-link type="primary" :href="'/helper/carrier'">{{getI18n("运营商列表", 'Carrier List')}}</el-link></li>
      <li> <el-link type="primary" href="/helper/faq/zh">{{getI18n("FAQ", 'FAQ')}}</el-link></li>
      <li> <el-link type="primary" href="https://global.cmlink.com/store/realname">{{getI18n("香港、台湾需要实名认证", 'Hong Kong and Taiwan require real-name registration')}}</el-link></li>
    </ul>
</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { getI18n } from '../utils'
export default defineComponent({
  name: 'HelpCenter'
})
</script>

<script lang="ts" setup>
onMounted(async () => {
  console.log('enter')
})
</script>

<style scoped>
.div-margin-top {
  margin-top: 10px;
}
li {
  margin-bottom: 10px;
}
</style>
